import React from "react"
import * as styles from "./Combinitation.module.scss"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import js from "../../images/hire-typescript/javaScript.svg"
import plus from "../../images/hire-typescript/plusType.svg"
const Combinitation = ({ strapiData }) => {
  const nextGenData = [
    {
      stcak1:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/python_8c88ca43a9.svg",
      stack2:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/angular_c9abc8a068.svg",
      stack3:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/postgres_2e4b7f86c7.svg",
      title: "Python + Angular + PostgreSQL",
      desc: "Python frameworks like Django on the backend, Angular on front end & PostgreSQL as a database can help develop secure & high-performing apps.",
    },
    {
      stcak1:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/python_8c88ca43a9.svg",
      stack2:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/vue_js_bb3714c901.svg",
      stack3:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/mysql_3a3167b854.svg",
      title: "Python + Vue.js + MySQL",
      desc: "Vue.js for the front end, MySQL as a database, and Flask (Python framework) for the backend can build versatile, scalable, and reliable apps.",
    },
    {
      stcak1:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/python_8c88ca43a9.svg",
      stack2:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/kotlin_3718e36fec.svg",
      stack3:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/mysql_3a3167b854.svg",
      title: "Python + Kotlin + MySQL",
      desc: "Kotlin for frontend, Flask for backend, and MySQL as a database help in the rapid development of flexible and efficient mobile app development.",
    },
    {
      stcak1:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/python_8c88ca43a9.svg",
      stack2:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/react_native_e3e77e4aed.svg",
      stack3:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/mysql_3a3167b854.svg",
      title: "Python + React + MySQL",
      desc: "Django (Python) on the backend, React on the front end, and MySQL as relational database creates versatile apps coupled with simplicity.",
    },
    {
      stcak1:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/python_8c88ca43a9.svg",
      stack2:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/swift_ccc093442d.svg",
      stack3: js,
      title: "Python + Swift + Firebase",
      desc: "Swift on the front end combined with Python on the back end and Firebase as a database can help develop versatile apps with real-time capabilities.",
    },
    {
      stcak1:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/python_8c88ca43a9.svg",
      stack2:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/typescript_1d5f3eede2.svg",
      stack3:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/postgres_2e4b7f86c7.svg",
      title: "Python + Typescript + PostgreSQL",
      desc: "Python for the backend, coupled with Typescript on the front end and PostgreSQL/MySQL, is a perfect blend for the development of scalable and versatile apps.",
    },
  ]
  return (
    <div className={styles.typescriptNextGen}>
      <Container>
        <h2
          className={styles.heading}
          dangerouslySetInnerHTML={{ __html: strapiData?.title }}
        />
        <div
          className={styles.description}
          dangerouslySetInnerHTML={{
            __html: strapiData?.subTitle,
          }}
        />
        <Row className={`gap-30 justify-content-center ${styles.cardWrapper}`}>
          {nextGenData.map((e, i) => (
            <Col xl={6} md={6} xs={12} key={i} className={styles.Golang}>
              <div className={styles.card1}>
                <div className={styles.imgs}>
                  <img
                    className={styles.typeScriptImg}
                    src={e?.stcak1}
                    decoding="async"
                    loading="lazy"
                    alt={e?.title}
                  />
                  <img className={styles.plus2} src={plus} alt="" />

                  <img
                    className={styles.typeScriptImg}
                    src={e?.stack2}
                    decoding="async"
                    loading="lazy"
                    alt={e?.title}
                  />
                  <img className={styles.plus} src={plus} alt="" />
                  <img
                    className={styles.typeScriptImg}
                    src={e?.stack3}
                    decoding="async"
                    loading="lazy"
                    alt={e?.title}
                  />
                </div>

                <h3>{e?.title}</h3>
                <p
                  dangerouslySetInnerHTML={{
                    __html: e?.desc,
                  }}
                />
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  )
}

export default Combinitation
