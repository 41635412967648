import { Link } from "gatsby"
import React, { useState } from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Button from "react-bootstrap/Button"
import stars4 from "../../images/hire-typescript/4.5Stars.svg"
import fiveStars from "../../images/hire-typescript/5Stars.svg"
import * as styles from "./HireDevelopers.module.scss"
import plus from "../../images/hire-typescript/btnPlus.svg"
import mernIcon from "../../images/hireMernStack/armyIcon.svg"
const HireDevelopers = ({ strapiData }) => {
  const [showMoreSkills, setShowMoreSkills] = useState(false)
  const [showMoreSkills1, setShowMoreSkills1] = useState(false)
  const [showMoreSkills2, setShowMoreSkills2] = useState(false)
  const [showLessSkills, setShowLessSkills] = useState(false)
  const [showLessSkills1, setShowLessSkills1] = useState(false)
  const [showLessSkills2, setShowLessSkills2] = useState(false)

  const toggleShowMoreSkills = () => {
    setShowMoreSkills(!showMoreSkills)
    setShowLessSkills(false)
  }
  const toggleShowMoreSkills1 = () => {
    setShowMoreSkills1(!showMoreSkills1)
  }
  const toggleShowMoreSkills2 = () => {
    setShowMoreSkills2(!showMoreSkills2)
  }
  const toggleShowLessSkills = () => {
    setShowMoreSkills(false)
    setShowLessSkills(!showLessSkills)
  }
  const toggleShowLessSkills1 = () => {
    setShowMoreSkills1(false)
    setShowLessSkills1(!showLessSkills1)
  }
  const toggleShowLessSkills2 = () => {
    setShowMoreSkills2(false)
    setShowLessSkills2(!showLessSkills2)
  }
  const devSDetails = [
    {
      name: "Daniel Henry",
      devImage:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/Group_1000006598_1_92e8aafc5b.png",
      experience: "8+ years of experience",
      desc: "Experience in dealing with global projects & developing cross-platform mobile apps. He is a pro in providing solutions that surpass client expectations.",
      rating: fiveStars,
      reviews: "<b>5.0</b> Reviews",
    },
    {
      name: "Laique A",
      devImage:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/Group_1000006598_1_51b3e927a2.png",
      experience: "5 years of experience",
      desc: "Consistently delivering scalable & seamless solutions Laique can be a great asset for your team with his innovative approach & top technical expertise.",
      rating: stars4,
      reviews: "<b>4.9</b> Stars Reviews",
    },
    {
      name: "Joseph Micheal",
      devImage:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/Group_1000006598_2_664ffe9239.png",
      experience: "2+ Years Of Experience",
      desc: "Joseph has excellent testing and debugging skills with the ability to write clean and maintainable code. His passion for continuous learning is marvelous.",
      rating: stars4,
      reviews: "<b>4.8</b> Stars Reviews",
    },
  ]
  const dataDev1 = [
    {
      skill: "Python",
    },
    {
      skill: "Flask",
    },
    {
      skill: "Django",
    },
    {
      skill: "DRF",
    },
    {
      skill: "FastAPI",
    },
    {
      skill: "ASP.NET",
    },
    {
      skill: "Git",
    },
    {
      skill: "TensorFlow",
    },
    {
      skill: "Pandas",
    },
    {
      skill: "PostgreSQL",
    },
    {
      skill: "Fast API",
    },
    {
      skill: "PyTorch",
    },
    {
      skill: "Fabric",
    },
    {
      skill: "Redis",
    },
    {
      skill: "SciPy",
    },
    {
      skill: "Bottle",
    },
    {
      skill: "OpenCV",
    },
    {
      skill: "PyQt",
    },
    {
      skill: "Tornado",
    },
    {
      skill: "NumPy",
    },
    {
      skill: "CherryPy SQLite",
    },
    {
      skill: "Pillow",
    },
  ]
  const dataDev2 = [
    {
      skill: "Python",
    },
    {
      skill: "Django",
    },

    {
      skill: "PyTorch",
    },
    {
      skill: "Neo4j",
    },
    {
      skill: "Dash",
    },
    {
      skill: "Framework",
    },
    {
      skill: "Fabric",
    },
    {
      skill: "Bottle",
    },
    {
      skill: "PyQt",
    },
    {
      skill: "Tornado",
    },
    {
      skill: "Django Rest Framework (DRF)",
    },
    {
      skill: "Git",
    },
    {
      skill: "PostgreSQL",
    },
  ]
  const dataDev3 = [
    {
      skill: "Python",
    },
    {
      skill: "Django",
    },

    {
      skill: "Mongo DB",
    },
    {
      skill: "Redis",
    },
    {
      skill: "Postgres",
    },
    {
      skill: "Jinja2",
    },
    {
      skill: "Django REST Framework",
    },
  ]
  return (
    <React.Fragment>
      <div className={styles.AngularDevelopersWeb}>
        <Container>
          <h2
            className={styles.heading}
            dangerouslySetInnerHTML={{ __html: strapiData?.title }}
          />
          <div
            className={styles.description}
            dangerouslySetInnerHTML={{
              __html: strapiData?.subTitle,
            }}
          />
          <Row className={styles.cardWrapper}>
            {devSDetails &&
              devSDetails.map((e, i) => (
                <Col lg={4} md={6} sm={12} xs={12} key={i}>
                  <div className={styles.card}>
                    <div className={` d-flex ${styles.developersData}`}>
                      <div>
                        <img
                          src={e?.devImage}
                          alt={e?.name}
                          decoding="async"
                          loading="lazy"
                          className={styles.devImages}
                        />
                      </div>
                      <div className={styles.devContent}>
                        <h3>{e?.name}</h3>
                        <p className={styles.experience}>{e?.experience}</p>
                      </div>
                    </div>
                    <div className={styles.allSkills}>
                      {i === 0 && (
                        <React.Fragment>
                          {dataDev1 &&
                            dataDev1
                              .slice(0, showMoreSkills ? dataDev1.length : 6)
                              .map((s, eIdx) => (
                                <div className={styles.skills} key={eIdx}>
                                  <div className={`${styles.skill}`}>
                                    {s?.skill}
                                  </div>
                                </div>
                              ))}
                          {showMoreSkills && (
                            <div>
                              <button
                                onClick={toggleShowLessSkills}
                                className={styles.btnShowMore}
                              >
                                See less
                              </button>
                            </div>
                          )}
                          {!showMoreSkills && (
                            <div>
                              <button
                                onClick={toggleShowMoreSkills}
                                className={styles.btnShowMore}
                              >
                                See more{" "}
                                <img
                                  className={styles.whitePlus}
                                  src={plus}
                                  alt="plus"
                                />
                              </button>
                            </div>
                          )}
                        </React.Fragment>
                      )}
                      {i === 1 && (
                        <React.Fragment>
                          {dataDev2 &&
                            dataDev2
                              .slice(0, showMoreSkills1 ? dataDev2.length : 6)
                              .map((sl, el) => (
                                <div className={styles.skills} key={el}>
                                  <div className={`${styles.skill}`}>
                                    {sl?.skill}
                                  </div>
                                </div>
                              ))}
                          {showMoreSkills1 && (
                            <div>
                              <button
                                onClick={toggleShowLessSkills1}
                                className={styles.btnShowMore}
                              >
                                See less
                              </button>
                            </div>
                          )}
                          {!showMoreSkills1 && (
                            <div>
                              <button
                                onClick={toggleShowMoreSkills1}
                                className={styles.btnShowMore}
                              >
                                See more{" "}
                                <img
                                  className={styles.whitePlus}
                                  src={plus}
                                  alt="plus"
                                />
                              </button>
                            </div>
                          )}
                        </React.Fragment>
                      )}
                      {i === 2 && (
                        <React.Fragment>
                          {dataDev3 &&
                            dataDev3
                              .slice(0, showMoreSkills2 ? dataDev3.length : 6)
                              .map((al, ei) => (
                                <div className={styles.skills} key={ei}>
                                  <div className={`${styles.skill}`}>
                                    {al?.skill}
                                  </div>
                                </div>
                              ))}
                          {showMoreSkills2 && (
                            <div>
                              <button
                                onClick={toggleShowLessSkills2}
                                className={styles.btnShowMore}
                              >
                                See less
                              </button>
                            </div>
                          )}
                          {!showMoreSkills2 && (
                            <div>
                              <button
                                onClick={toggleShowMoreSkills2}
                                className={styles.btnShowMore}
                              >
                                See more{" "}
                                <img
                                  className={styles.whitePlus}
                                  src={plus}
                                  alt="plus"
                                />
                              </button>
                            </div>
                          )}{" "}
                        </React.Fragment>
                      )}
                    </div>
                    <p className={styles.cardDesc}>{e?.desc}</p>
                    <div className={styles.typedevBtn}>
                      <Link to="#hirePython">
                        <Button className={styles.typeBtn}>
                          <img
                            src={mernIcon}
                            alt="icon"
                            className={styles.mernIcon}
                          />
                          {`Hire ${e?.name}`}
                        </Button>
                      </Link>
                    </div>
                    <div className={styles.bottomRating}>
                      <img
                        src={e?.rating}
                        alt="stars"
                        decoding="async"
                        loading="lazy"
                      />
                      <div
                        className={styles.review}
                        dangerouslySetInnerHTML={{
                          __html: e?.reviews,
                        }}
                      />
                    </div>
                  </div>
                </Col>
              ))}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}
export default HireDevelopers
