import React, { useState } from "react"
import Nav from "react-bootstrap/Nav"
import Container from "react-bootstrap/Container"
import Tab from "react-bootstrap/Tab"
import "./AngularTechStacks.scss"
import * as styles from "./AngularTechStacks.module.scss"

const data = [
  {
    title: "Database",
    tech: [
      {
        name: "PostgreSQL",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/postgres_2e4b7f86c7.svg",
      },
      {
        name: "MongoDB",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/mongodb_93ed56f95b.svg",
      },
      {
        name: "MySQL",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/mysql_3a3167b854.svg",
      },
      {
        name: "Oracle",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/oracals_77923e9955.svg",
      },
      {
        name: "SQLite",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/sqlite_4aed268eb9.svg",
      },
      {
        name: "Redis",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/redis_6562c9840a.svg",
      },
      {
        name: "Neo4j",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/neo4j_a3373a1443.svg",
      },
      {
        name: "CouchDB",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/counchdb_22287b88e7.svg",
      },
      {
        name: "SnowFlake",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/snowflake_aecb5f48e0.svg",
      },
      {
        name: "DynamoDB",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/dynamodb_0516dbe606.svg",
      },
    ],
  },
  {
    title: "Libraries",
    tech: [
      {
        name: "Requests",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/request_c6207e9ade.svg",
      },
      {
        name: "Scrapy",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/scrapy_ddf79de7a7.svg",
      },
      {
        name: "Pillow",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/pillow_f67549e9b5.svg",
      },
      {
        name: "Fabric",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/fabric_e8c0c31930.svg",
      },
      {
        name: "Scikit-learn",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/scikit_learn_330ed8f723.svg",
      },
      {
        name: "FastAPI",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/fast_api_a0e1187894.svg",
      },
      {
        name: "NumPy",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/numpy_623ce04a65.svg",
      },
      {
        name: "Pandas",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/pandas_3969b29acb.svg",
      },
      {
        name: "Matplotlib",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/matplotlib_ff2030b277.svg",
      },
      {
        name: "SciPy",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/scipy_423a2e9412.svg",
      },
      {
        name: "TensorFlow",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/tensorflow_aeb39a664f.svg",
      },
      {
        name: "PyTorch",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/pytorch_8648c3c1c4.svg",
      },
      {
        name: "Keras",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/keras_d599f8d8f1.svg",
      },
      {
        name: "Django",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/django_4bc3a98311.svg",
      },
      {
        name: "Stream Lit",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/Streamlit_407ef7da6b.svg",
      },
      {
        name: "Boto3",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/boto_3_c2a9faf724.svg",
      },
      {
        name: "Flask",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/Flask_824450e438.svg",
      },
      {
        name: "Beautiful Soup",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/beautiful_soap_dec7813366.svg",
      },
      {
        name: "OpenCV",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/open_cv_b578883d01.svg",
      },
      {
        name: "Seaborn",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/seaborn_171022b9fa.svg",
      },
    ],
  },
  {
    title: "Framework",
    tech: [
      {
        name: "PyTorch",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/pytorch_8648c3c1c4.svg",
      },
      {
        name: "Caffe",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/caffe_bfb28b35ed.svg",
      },
      {
        name: "Theano",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/theano_70b7c428b8.svg",
      },
      {
        name: "Flask",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/Flask_824450e438.svg",
      },
      {
        name: "Bottle",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/bottle_861a3b037d.svg",
      },
      {
        name: "CherryPy",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/cherrypy_fa37f40b9e.svg",
      },
      {
        name: "Django",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/django_4bc3a98311.svg",
      },
      {
        name: "FastAPI",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/fast_api_a0e1187894.svg",
      },
      {
        name: "Pyramid",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/Pyramid_c368437073.svg",
      },
      {
        name: "Tornado",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/tornado_fde941f497.svg",
      },
      {
        name: "PyQt",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/py_qt_65a5534a08.svg",
      },
      {
        name: "Dash Framework",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/dash_framework_bde8a52ccf.svg",
      },
    ],
  },
  {
    title: "DevOps",
    tech: [
      {
        name: "Docker",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/docker_f254a7efb9.svg",
      },
      {
        name: "Azure",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/azure_d13b8824b1.svg",
      },
      {
        name: "Kubernetes",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/kubernetes_6d5c000c75.svg",
      },
      {
        name: "Google Cloud",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/google_cloud_841494810d.svg",
      },
      {
        name: "Serverless Functions",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/serverless_functions_6165dae4ee.svg",
      },
      {
        name: "AWS",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/aws_4a81b2e2eb.svg",
      },
      {
        name: "Digital Ocean",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/digital_ocean_9ed3c7087f.svg",
      },
    ],
  },
  {
    title: "Testing",
    tech: [
      {
        name: "Postman",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/postman_1262676b3d.svg",
      },
      {
        name: "Jmeter",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/jmeter_ae7d5ffab8.svg",
      },
      {
        name: "Selenium",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/selenium_ae2bcac6a3.svg",
      },
      {
        name: "Cypress",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/cypress_fbcedc958b.svg",
      },
      {
        name: "BrowserStack",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/browserstack_4bdae2829e.svg",
      },
      {
        name: "Bugzilla",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/bugzilla_26f77b961f.svg",
      },
      {
        name: "SoapUI",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/soap_ui_9d66914504.svg",
      },
      {
        name: "Jasmine",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/jasmine_84cb9a34a0.svg",
      },
      {
        name: "Karma",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/karma_e16754cbb0.svg",
      },
      {
        name: "Jenkins",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/jenkins_b7ff843ac0.svg",
      },
    ],
  },
]

const AngularTechStacks = ({ strapiData }) => {
  const [activeTab, setActiveTab] = useState(data[0]?.title)

  const handleTabClick = label => {
    setActiveTab(label)
  }

  return (
    <div className="WebTabStacksAngularHire">
      <div className={styles.TechStacksWebHireAngular}>
        <Container>
          <h2
            className={styles.heading}
            dangerouslySetInnerHTML={{ __html: strapiData?.title }}
          ></h2>
          <div
            className={styles.description}
            dangerouslySetInnerHTML={{
              __html: strapiData?.subTitle,
            }}
          />
          <Tab.Container
            id="left-tabs-example"
            defaultActiveKey={`${data[0]?.title}`}
          >
            <div className={styles.cardsContentAngular}>
              <div>
                <Nav variant="pills" className={`WebTabStacksAngularHire`}>
                  {data &&
                    data?.map((e, i) => (
                      <Nav.Item key={i} className="cards">
                        <div
                          eventKey={`${e?.title}`}
                          className={`tabLink ${
                            activeTab === e.title ? "active" : ""
                          }`}
                          onClick={() => handleTabClick(e.title)}
                        >
                          <div className={styles.tabData}>
                            <p
                              dangerouslySetInnerHTML={{
                                __html: e?.title,
                              }}
                            />
                          </div>
                        </div>
                      </Nav.Item>
                    ))}
                </Nav>
              </div>
              <Tab.Content className="contentBlockAngularWeb">
                {data &&
                  data?.map((e, i) => (
                    <div
                      key={i}
                      className={`tab-pane ${
                        activeTab === e.title ? "active" : ""
                      }`}
                    >
                      <div className={styles.techIcon}>
                        {e?.tech &&
                          e?.tech?.map((el, i) => (
                            <div className={`${styles.iconssCard}`} key={i}>
                              <div className={styles.technologyIcon}>
                                <div className={styles.techImg}>
                                  <img
                                    decoding="async"
                                    loading="lazy"
                                    src={el?.icon}
                                    alt={el?.name}
                                  />
                                </div>
                                <h3>{el?.name}</h3>
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  ))}
              </Tab.Content>
            </div>
          </Tab.Container>
        </Container>
      </div>
    </div>
  )
}
export default AngularTechStacks
