// extracted by mini-css-extract-plugin
export var accImg = "Engineering-module--accImg--ed4e7";
export var approachImg = "Engineering-module--approachImg--f7602";
export var btn_white1_border = "Engineering-module--btn_white1_border--4ff26";
export var description = "Engineering-module--description--a1f42";
export var engineeringSQA = "Engineering-module--engineeringSQA--46bd6";
export var head = "Engineering-module--head--3f2f8";
export var heading = "Engineering-module--heading--c39b6";
export var keyBtn = "Engineering-module--keyBtn--2c5a2";
export var nextGenRight = "Engineering-module--nextGenRight--e98da";
export var quesColor = "Engineering-module--quesColor--4bb18";
export var quesColorSelected = "Engineering-module--quesColorSelected--d9984";
export var someColor = "Engineering-module--someColor--0f554";
export var textColor = "Engineering-module--textColor--75762";