// extracted by mini-css-extract-plugin
export var AngularDevelopersWeb = "HireDevelopers-module--AngularDevelopersWeb--be499";
export var allSkills = "HireDevelopers-module--allSkills--a3381";
export var bottomRating = "HireDevelopers-module--bottomRating--3406a";
export var btnShowMore = "HireDevelopers-module--btnShowMore--763a6";
export var card = "HireDevelopers-module--card--9603d";
export var cardDesc = "HireDevelopers-module--cardDesc--8e03f";
export var cardWrapper = "HireDevelopers-module--cardWrapper--93f5b";
export var description = "HireDevelopers-module--description--c4cee";
export var devContent = "HireDevelopers-module--devContent--0d62c";
export var devImages = "HireDevelopers-module--devImages--dda38";
export var developersData = "HireDevelopers-module--developersData--ffb0e";
export var experience = "HireDevelopers-module--experience--4d220";
export var expert = "HireDevelopers-module--expert--b87ad";
export var heading = "HireDevelopers-module--heading--9f5dc";
export var mernIcon = "HireDevelopers-module--mernIcon--50087";
export var review = "HireDevelopers-module--review--3d414";
export var skill = "HireDevelopers-module--skill--5f8d1";
export var skills = "HireDevelopers-module--skills--26960";
export var typeBtn = "HireDevelopers-module--typeBtn--6575e";
export var typedevBtn = "HireDevelopers-module--typedevBtn--69d7b";
export var whitePlus = "HireDevelopers-module--whitePlus--4153c";