// extracted by mini-css-extract-plugin
export var AngularIndusTechHireWeb = "AngularTechStacksMobile-module--AngularIndusTechHireWeb--562c9";
export var cardBg = "AngularTechStacksMobile-module--cardBg--59216";
export var cards = "AngularTechStacksMobile-module--cards--efafd";
export var cardsContent = "AngularTechStacksMobile-module--cardsContent--c03a3";
export var description = "AngularTechStacksMobile-module--description--ae9e6";
export var heading = "AngularTechStacksMobile-module--heading--82315";
export var iconssCard = "AngularTechStacksMobile-module--iconssCard--6f84a";
export var nav = "AngularTechStacksMobile-module--nav--01dc0";
export var navItem = "AngularTechStacksMobile-module--nav-item--62b43";
export var navbarBlock = "AngularTechStacksMobile-module--navbarBlock--af434";
export var tabData = "AngularTechStacksMobile-module--tabData--afc96";
export var tech = "AngularTechStacksMobile-module--tech--db425";
export var techIcon = "AngularTechStacksMobile-module--techIcon--d6038";
export var techImg = "AngularTechStacksMobile-module--techImg--c13bd";
export var technologyIcon = "AngularTechStacksMobile-module--technologyIcon--d5b26";