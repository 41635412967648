// extracted by mini-css-extract-plugin
export var bannerHeading = "Banner-module--bannerHeading--e5a75";
export var bannerSubtitle = "Banner-module--bannerSubtitle--95682";
export var bannerSubtitle2 = "Banner-module--bannerSubtitle2--bf26b";
export var cards = "Banner-module--cards--87639";
export var form = "Banner-module--form--8fb07";
export var heading = "Banner-module--heading--35fc2";
export var hireAndroidTopBanner = "Banner-module--hireAndroidTopBanner--6b5d3";
export var talent = "Banner-module--talent--d9516";
export var text = "Banner-module--text--335be";
export var tick = "Banner-module--tick--c2053";