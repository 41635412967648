// extracted by mini-css-extract-plugin
export var Golang = "Combinitation-module--Golang--100ce";
export var bannerBtnGolangBook = "Combinitation-module--bannerBtnGolangBook--52a2b";
export var btn_white2_border_book_Goolang_dev = "Combinitation-module--btn_white2_border_book_Goolang_dev--06955";
export var card1 = "Combinitation-module--card1--7d100";
export var cardWrapper = "Combinitation-module--cardWrapper--38755";
export var description = "Combinitation-module--description--401f8";
export var heading = "Combinitation-module--heading--e6e27";
export var imgs = "Combinitation-module--imgs--eeb74";
export var plus = "Combinitation-module--plus--ea1dd";
export var plus2 = "Combinitation-module--plus2--b36c2";
export var typeScriptImg = "Combinitation-module--typeScriptImg--cee8d";
export var typescriptNextGen = "Combinitation-module--typescriptNextGen--bbaa0";