import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import * as styles from "./Banner.module.scss"
import FaqForm from "../common/hireDeveloperForm"

const Banner = ({ strapiData, pageName }) => {
  return (
    <div id="hirePython" className={styles.hireAndroidTopBanner}>
      <Container>
        <Row>
          <Col xl={7} md={12} lg={6}>
            <div className={styles.heading}>
              <h1 className={styles.bannerHeading}>{strapiData?.title}</h1>
              <p className={styles.bannerSubtitle}>{strapiData?.subTitle}</p>
              <p
                className={styles.bannerSubtitle2}
                dangerouslySetInnerHTML={{
                  __html: strapiData?.description?.description,
                }}
              />
              <p className={styles.bannerSubtitle}>{strapiData?.subTitle2}</p>
            </div>
          </Col>
          <Col
            xl={5}
            md={12}
            lg={6}
            className={`  ${styles.form}`}
            style={{ minHeight: "300px" }}
          >
            <FaqForm pageName={pageName} hirePython={true} />
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Banner
